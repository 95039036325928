<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Determine which orbital labels fit each of the different classes of orbitals (atomic,
        hybrid, and molecular).
      </p>

      <v-simple-table style="max-width: 550px">
        <thead>
          <tr>
            <th style="font-size: 15px">Orbital Label</th>
            <th style="font-size: 15px">Atomic Orbital</th>
            <th style="font-size: 15px">Hybrid Orbital</th>
            <th style="font-size: 15px">Molecular Orbital</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(option, i) in options1" :key="option.value">
            <td>
              <stemble-latex :content="option.text" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="ao" value="ao" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="ho" value="ho" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="mo" value="mo" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question44',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
      },
      options1: [
        {text: '$\\text{sp}$', value: '1'},
        {text: '$\\text{f}$', value: '2'},
        {text: '$\\text{d}$', value: '3'},
        {text: '$\\sigma_{\\text{2s}}$', value: '4'},
        {text: '$\\text{sp}^3\\text{d}$', value: '5'},
        {text: '$\\pi_{\\text{2p}}$', value: '6'},
        {text: '$\\text{sp}^3$', value: '7'},
        {text: '$\\text{s}$', value: '8'},
        {text: '$\\text{sp}^2$', value: '9'},
        {text: '$\\text{p}$', value: '10'},
      ],
    };
  },
};
</script>
